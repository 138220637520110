{
	"general" : {
		"Add": "Aggiungi",
		"Cancel": "Annulla",
		"Edit": "Modifica",
		"Share": "Condividi",
		"View": "Visualizza",
		"Loading": "Caricamento...",
		"Select": "Seleziona...",

		"APP_Language": "Lingua APP",
		"International_sale_contract": "Contratto di vendita internazionale",
		"INTERNATION_SALE_CONTRACT_DESCRIPTION": "Contratto di vendita internazionale della CCI (Prodotti manifatturati destinati alla rivendita)",
		"PRODUCT_EXPORT_ALERT_MSG": "ATTENZIONE! Per esportare i prodotti indicati nel paese di destinazione è necessario disporre di autorizzazioni speciali. Assicurati di avere tutte le autorizzazioni prima di concludere il contratto.",
		"Save": "Salva",

		"Business_name": "Ragione sociale",
		"Vat_code": "Partita IVA",
		"Street_or_Square": "Via o Piazza",
		"Country": "Stato",
		"City": "Città",
		"Province": "Provincia",
		"Postal_code": "CAP",
		
		"Data": "Dati",
		"Seller": "Venditore",
		"Buyer": "Compratore",
		"Legal_representative": "Rappresentante legale",
		"Contact_person": "Persona di contatto",
		"Name": "Nome",
		"Surname": "Cognome",
		"Email": "Email",
		"Phone": "Telefono",
		"Signatory": "Firmatario",
		"SIGNATORY_TIP": "Specifica se il legale rappresentante è anche firmatario del contratto.",
		"Personal_responsibility": "Responsabilità personali",
		"PERSONAL_RESPONSIBILITY_TIP": "Specifica se il legale rappresentante risponde personalmente di questo contratto. In caso contrario, si assume che la responsabilità ricada sull'azienda.",

		"Login_to_your_account": "Accedi al tuo account",
		"Manage_your_contracts": "Gestisci i tuou contratti",
		"Password": "Password",
		"PLACEHOLDER_Email": "La tua email",
		"PLACEHOLDER_Password": "La tua password",

		"Terms": "Condizioni generali",
		"TERMS_DESCRIPTION": "Qui andranno specificate tutte le condizioni generali.",
			
		"Contract_saved_title": "Contratto salvato!",
		"Contract_saved_subtitle": "La transazione è stata memorizzata sulla Blockchain",

		"VALIDATION_REQUIRED_FIELD": "Campo obbligatorio.",
		"VALIDATION_PHONE_FIELD": "Campo obbligatorio. Deve contenere da 8 a 12 cifre."
	},
	"intro": {
		"WELCOME": "Benvenuto/a nella compilazione del contratto di vendita internazionale!",
		"SELLER": "Qui andranno inseriti i dati del venditore.",
		"BUYER": "Qui quelli del compratore!",
		"GENERAL_TERMS": "In questo blocco trovi le condizioni generali del contratto.",
		"SPECIAL_TERMS": "In tutti i blocchi successivi trovi invece le condizioni speciali del contratto."
	},
	"A1": {
		"TITLE": "A-1 Prodotti venduti",
		"Unnamed_product": "Prodotto senza nome",
		"Code": "Codice",
		"Product_code": "Codice prodotto",
		"Description": "Descrizione",
		"Product_description": "Descrizione prodotto",
		"Quantity": "Quantità",
		"Price": "Prezzo",
		"Origin": "Origine",
		"Product_origin": "Origine del prodotto",
		"Add_product": "Aggiungi prodotto",
		"Delete_product": "Elimina prodotto"
	},
	"A2": {
		"TITLE": "A-2 Prezzo contrattuale",
		"Amount": "Importo",
		"Total_amount": "Importo totale"
	},
	"A3": {
		"TITLE": "A-3 Termini di consegna",
		"ALERT_MSG": "Termini raccomandati (conformemente agli Incoterms 1990): v. Introduzione, § 5)",
		"Terms_choose": "Scelta termini",
		"Shipping_carrier": "Vettore consegna",
		"Contact_person": "Persona di contatto",
		"Catalog": "Catalogo",
		"Select": "Seleziona...",
		"Term": "Termine",
		"Notes": "Note",
		"Place": "Luogo",
		"EXW": "EXW - Franco fabbrica",
		"FCA": "FCA - Franco vettore",
		"FAS": "FAS - Franco lungo bordo",
		"FOB": "FOB - Franco a bordo",
		"CFR": "CFR - Costo e nido",
		"CPT": "CPT - Costo e nolo",
		"CIF": "CIF - Costo, assicurazione e nolo",
		"CIP": "CIP - Trasporto e assicurazione pagati fino a",
		"CPT": "CPT - Trasporto pagato fino a",
		"DAF": "DAF - Reso frontiera",
		"DAP": "DAP - Reso nel luogo di destinazione convenuto",
		"DAT": "DAT - Reso al terminal",
		"DDP": "DDP - Reso sdoganato",
		"DDU": "DDU - Reso non sdoganato",
		"DEQ": "DEQ - Reso banchina",
		"DES": "DES - Reso ex ship",
		"DPU": "DPU - Consegnato in luogo scaricato"
	},
	"A4": {
		"TITLE": "A-4 Termine (data) di consegna",
		"ALERT_MSG": "Indicare qui la data o il periodo (ad es. settimana o mese) in cui o entro cui il Venditore è tenuto ad adempiere al suo obbligo di consegna conformemente alla clausola A4 del rispettivo Incoterm: v. Introduzione, § 6)",
		"Shipping_date_min": "Data di consegna (min)",
		"Shipping_date_max": "Data di consegna (max)",
		"DATE_PLACEHOLDER": "gg/mm/AAAA"
	},
	"A5": {
		"TITLE": "A-5 Ispezione dei prodotti (Art. 3)",
		"Inspection_type": "Tipo di ispezione",
		"Select": "Seleziona...",
		"Before_shipping": "Prima della spedizione",
		"Other": "Altro",
		"Notes": "Note"
	},
	"A6": {
		"TITLE": "A-6 Riserva di proprietà (Art. 7)",
		"Retention_of_title": "Riserva di proprietà",
		"None": "Nessuna",
		"Simple": "Semplice",
		"Extended": "Estesa",
		"TIP_MSG": "Seleziona la riserva di proprietà per il trasferimento della proprietà dei prodotti dal venditore all'acquirente."
	},
	"A7": {
		"TITLE": "A-7 Condizioni di pagamento (Art. 5)",
		"Delayed_payment": "Pagamento posticipato (art. 5.1)",
		"Prepayment": "Pagamento anticipato (art. 5.2)",
		"Payment_against_documents": "Pagamento contro documenti (art. 5.5)",
		"Irrevocable_documentary_credit": "Credito documentario irrevocabile (art. 5.3)",
		"Other": "Other"
	},
	"A8": {
		"TITLE": "A-8 Documenti",
		"ALERT_MSG": "Indicare qui i documenti che il Venditore deve procurare. Si consiglia alle parti di controllare l'Incoterm da loro scelto alla casella A-3 delle presenti Condizioni Speciali. Per quanto riguarda i documenti di trasporto, v. anche Introduzione, § 8.",
		"Invoice": "Fattura commerciale",
		"DDT": "Documento di trasporto",
		"Insurance_document": "Documento di assicurazione",
		"Origin_certificate": "Certificato di origine",
		"Preferential_origin_certificate": "Certificato di origine preferenziale",
		"Inspection_certificate": "Certificato di ispezione",
		"Packing_list": "Packing_list",
		"Other": "Altro"
	},
	"A9": {
		"TITLE": "A-9 Data di risoluzione",
		"ALERT_MSG": "DA COMPLETARE SOLO SE LE PARTI DESIDERANO MODIFICARE L'ARTICOLO 10.3. Se, per qualsiasi ragione (inclusa la forza maggiore), i prodotti non sono stati consegnati entro la data di risoluzione il Compratore avrà il diritto di RISOLVERE IMMEDIATAMENTE IL CONTRATTO MEDIANTE SEMPLICE COMUNICAZIONE SCRITTA AL VENDITORE.",
		"Termination_date": "Data di risoluzione",
		"DATE_PLACEHOLDER": "gg/mm/AAAA"
	},
	"A10": {
		"TITLE": "A-10 Responsabilità per ritardata consegna (Art. 10.1, 10.4, 11.3)",
		"ALERT_MSG": "DA COMPLETARE SOLO SE LE PARTI DESIDERANO MODIFICARE GLI ARTT. 10.1, 10.4 o 11.3.",
		"LATE_DELIVERY_PENALTY_CTA": "La penale per ritardata consegna sarà della percentuale di seguito specificata (del prezzo dei prodotti consegnati in ritardo) per ogni settimana di ritardo, entro il limite massimo in percentuale di seguito specificato (del prezzo dei prodotti di cui sopra).",
		"EXCEED_AMOUNT_CTA": "In caso di risoluzione per ritardata consegna, la responsabilità del Venditore per danni da ritardata consegna è limitata alla percentuale di seguito specificata del prezzo dei prodotti non consegnati.",
		"SELLER_RESPONSABILITY_CTA": "La responsabilità del Venditore per danni derivanti da non conformità della merce sarà limitata al danno provato (incluso il danno consequenziale, mancato guadagno, ecc.) entro il limite massimo della percentuale di seguito specificata del prezzo del contratto.",
		"Price_percentage": "Percentuale del prezzo",
		"Max_limit_percentage": "Percentuale del limite massimo",
		"Seller_percentage": "Percentuale del venditore"
	},
	"A11": {
		"TITLE": "A-11 Limitazione di responsabilità per non conformità (Art. 11)",
		"ALERT_MSG": "DA COMPLETARE SOLO SE LE PARTI DESIDERANO MODIFICARE L'ART. 11.5",
		"PRICE_REDUCTION_PERCENTAGE_CTA": "La riduzione di prezzo per aver accettato prodotti non conformi non potrà eccedere la percentuale di seguito specificata del prezzo di tali prodotti.",
		"PRICE_REDUCTION_VALUE_CTA": "La riduzione di prezzo per aver accettato prodotti non conformi non potrà eccedere l'ammontare di seguito specificato.",
		"Percentage": "Percentuale",
		"Amount": "Ammontare"
	},
	"A12": {
		"TITLE": "A-12 Limitazione di responsabilità in caso il compratore decida di tenere prodotti non conformi (Art. 11.6)",
		"ALERT_MSG": "DA COMPLETARE SOLO SE LE PARTI DESIDERANO MODIFICARE L'ART. 11.6",
		"EXCEED_PERCENTAGE_CTA": "La riduzione di prezzo per aver accettato prodotti  non conformi non potrà eccedere la percentuale (specificata) del prezzo di tali prodotti.",
		"EXCEED_AMOUNT_CTA": "La riduzione di prezzo per aver accettato prodotti  non conformi non potrà eccedere l'ammontare (specificata).",
		"Percentage": "Percentuale",
		"Amount": "Ammontare"
	},
	"A13": {
		"TITLE": "A-13 Termine di decadenza (Art. 11.8)",
		"ALERT_MSG": "DA COMPLETARE SOLO SE LE PARTI DESIDERANO MODIFICARE L'ART. 11.8",
		"DAYS_MSG": "Qualsiasi azione per non conformità dei prodotti (come definita all'articolo 11.8) dev'essere promossa dal Venditore entro i giorni dalla data di arrivo dei prodotti a destinazione specificati di seguito.",
		"Days": "Days"
	},
	"A14": {
		"TITLE": "A-14 Legge applicabile (Art. 1.2)",
		"ALERT_MSG": "Da completare solo se le parti desiderano sottoporre il contratto di vendita ad una legge nazionale invece che alla CVIM. La soluzione indicata qui sotto non è raccomandabile (v. Introduzione, § 3).",
		"LAW_MSG": "Il presente contratto di vendita è sottoposto alla legge interna seguente.",
		"LAW2_MSG": "Qualsiasi questione non coperta dalla CVIM sarà regolata dalla legge seguente.",
		"Law": "Legge"
	},
	"A15": {
		"TITLE": "A-15 Risoluzione delle controversie (Art. 14)",
		"ALERT_MSG": "Le due soluzioni indicate sotto (arbitrato o tribunali ordinari) sono alternative: le parti non possono sceglierle ambedue. In assenza di scelta si applicherà l'arbitrato della CCI, conformemente all'art. 14.",
		"Dispute_resolution": "Risoluzione delle controversie",
		"OPTION_0": "ADR - Risoluzione alternativa delle controversie",
		"OPTION_1": "ARBITRATO",
		"OPTION_2": "CCI (conformemente all'art. 14.1)",
		"OPTION_3": "Altro (specificare)",
		"OPTION_4": "Giurisdizione ordinaria (specificare)"
	},
	"A16": {
		"TITLE": "A-16 Altro",
		"Other": "Altro"
	},
	"accountPage": {
		"My_account": "Il mio account",
		"Anonymous_identity": "Identità anonima",
		"My_contracts": "I miei contratti",
		"Add": "Aggiungi",
		"Contract_model": "Modello di contratto",
		"My_role": "Il mio ruolo",
		"Seller": "Venditore",
		"Buyer": "Compratore",
		"Contract_name": "Nome del contratto",
		"Create_contract": "Crea contratto"
	},
	"editAccountPage": {
		"Name": "Nome",
		"Surname": "Cognome",
		"Email": "Email",
		"Phone": "Telefono",
		"PLACEHOLDER_name": "Il tuo nome",
		"PLACEHOLDER_surname": "Il tuo cognome",
		"PLACEHOLDER_email": "La tua email",
		"PLACEHOLDER_phone": "Il tuo telefono"
	},
	"viewContractPage": {
		"View_contract": "Visualizza contratto",
		"Contract_draft": "Contratto in bozza",
		"CONTRACT_DRAFT_MSG": "Questo contratto è una bozza. Salvalo sulla blockchain per storicizzarlo in maniera persistente e sicura tramite sistema blockchain.",
		"Store_contract": "Salva contratto",
		"Contract_saved": "Contratto salvato",
		"CONTRACT_SAVED_MSG": "Questo contratto è stato storicizzato in maniera persistente e sicura tramite sistema blockchain ed è pronto per essere firmato.",
		"Sign_contract": "Firma contratto",
		"Contract_signed": "Contratto firmato",
		"CONTRACT_SIGNED_MSG": "Questo contratto è stato storicizzato e firmato in maniera persistente e sicura tramite sistema blockchain. La merce è pronta per essere spedita.",
		"Contract_status": "Stato del contratto",
		"Saved": "Salvato",
		"Signed": "Firmato",
		"Shipped": "Spedito",
		"Delivered": "Consegnato",
		"Completed": "Completato",
		"Contract_details": "Dettagli del contratto",
		"Contract_id": "ID del contratto",
		"Contract_name": "Nome del contratto",
		"Contract_model": "Modello di contratto",
		"Seller": "Venditore",
		"Buyer": "Compratore",
		"Seller_signature": "Firma del venditore",
		"Buyer_signature": "Firma del compratore",
		"Change_log": "Registro delle modifiche",
		"Open_PDF": "Apri PDF"
	},
	"viewContractChangesPage": {
		"Change_log": "Registro delle modifiche",
		"Last_changes" : "Ultime modifiche",
		"LAST_CHANGES_DESCRIPTION": "Qui trovi tutte le modifiche effettuate al contratto"
	},
	"shareContractPage": {
		"Share_your_contract": "Condividi il tuo contratto",
		"SHARE_MSG": "Scrivi un messaggio e condividi il tuo contratto",
		"SHARE_TEXTAREA_MSG": "Ciao! Ecco il contratto che ho inizializzato, clicca per aggiungerlo al tuo account TradeOnChain!",
		"SHARE_CTA": "Condividi",
		"URL_MSG": "Link di condivisione del contratto",
		"URL_CTA": "Copia link",
		"QRCODE_MSG": "QR Code di condivisione del contratto"
	},
	"addContractPage": {
		"Add_contract": "Aggiungi contratto",
		"ADD_CONTRACT_MSG": "Clicca sul pulsante 'Aggiungi' per aggiungere questo contratto al tuo account e collaborare alla stesura."
	},
	"languages": {
		"Italian": "Italiano",
		"English": "Inglese"
	},
	"countries": {
		"af": "Afghanistan",
        "al": "Albania",
        "dz": "Algeria",
        "ad": "Andorra",
        "ao": "Angola",
        "ar": "Argentina",
        "am": "Armenia",
        "au": "Australia",
        "at": "Austria",
        "az": "Azerbaigian",
        "bh": "Bahrein",
        "bd": "Bangladesh",
        "by": "Bielorussia",
        "be": "Belgio",
        "bz": "Belize",
        "bj": "Benin",
        "bt": "Bhutan",
        "bo": "Bolivia",
        "ba": "Bosnia-Erzegovina",
        "bw": "Botswana",
        "br": "Brasile",
        "bn": "Brunei",
        "bg": "Bulgaria",
        "bf": "Burkina Faso",
        "bi": "Burundi",
        "kh": "Cambogia",
        "cm": "Camerun",
        "ca": "Canada",
        "cv": "Capo Verde",
        "td": "Ciad",
        "cl": "Cile",
        "cn": "Cina",
        "co": "Colombia",
        "cg": "Congo",
        "cr": "Costa Rica",
        "hr": "Croazia",
        "cu": "Cuba",
        "cy": "Cipro",
        "cz": "Repubblica Ceca",
        "dk": "Danimarca",
        "dj": "Gibuti",
        "do": "Repubblica Dominicana",
        "ec": "Ecuador",
        "eg": "Egitto",
        "sv": "El Salvador",
        "ee": "Estonia",
        "et": "Etiopia",
        "fi": "Finlandia",
        "fr": "Francia",
        "ga": "Gabon",
        "gm": "Gambia",
        "ge": "Georgia",
        "de": "Germania",
        "gh": "Ghana",
        "gr": "Grecia",
        "gt": "Guatemala",
        "gn": "Guinea",
        "ht": "Haiti",
        "hn": "Honduras",
        "hu": "Ungheria",
        "is": "Islanda",
        "in": "India",
        "id": "Indonesia",
        "ir": "Iran",
        "iq": "Iraq",
        "ie": "Irlanda",
        "il": "Israele",
        "it": "Italia",
        "jm": "Giamaica",
        "jp": "Giappone",
        "jo": "Giordania",
        "kz": "Kazakistan",
        "ke": "Kenya",
        "kr": "Corea del Sud",
        "kw": "Kuwait",
        "lv": "Lettonia",
        "lb": "Libano",
        "lr": "Liberia",
        "ly": "Libia",
        "lt": "Lituania",
        "lu": "Lussemburgo",
        "mg": "Madagascar",
        "my": "Malesia",
        "ml": "Mali",
        "mt": "Malta",
        "mx": "Messico",
        "mn": "Mongolia",
        "me": "Montenegro",
        "ma": "Marocco",
        "mz": "Mozambico",
        "mm": "Myanmar",
        "np": "Nepal",
        "nl": "Paesi Bassi",
        "nz": "Nuova Zelanda",
        "ng": "Nigeria",
        "no": "Norvegia",
        "om": "Oman",
        "pk": "Pakistan",
        "pa": "Panama",
        "py": "Paraguay",
        "pe": "Perù",
        "ph": "Filippine",
        "pl": "Polonia",
        "pt": "Portogallo",
        "qa": "Qatar",
        "ro": "Romania",
        "ru": "Russia",
        "rw": "Ruanda",
        "sa": "Arabia Saudita",
        "sn": "Senegal",
        "rs": "Serbia",
        "sg": "Singapore",
        "sk": "Slovacchia",
        "si": "Slovenia",
        "za": "Sudafrica",
        "es": "Spagna",
        "lk": "Sri Lanka",
        "se": "Svezia",
        "ch": "Svizzera",
        "sy": "Siria",
        "tw": "Taiwan",
        "th": "Thailandia",
        "tn": "Tunisia",
        "tr": "Turchia",
        "ua": "Ucraina",
        "ae": "Emirati Arabi Uniti",
        "gb": "Regno Unito",
        "us": "Stati Uniti",
        "uy": "Uruguay",
        "ve": "Venezuela",
        "vn": "Vietnam",
        "zm": "Zambia",
        "zw": "Zimbabwe"
	}
}